module.exports={
  "data": {
    "AO": {
      "BU": 0.02405259376623,
      "BU_CT_high": 0.0351584466632632,
      "High": 0.0279245347206258,
      "High_CT_high": 0.0355967948908014,
      "Low": 0.0242885534934388,
      "Low_CT_high": 0.035089351016211
    },
    "BI": {
      "BU": 0.0266290894560436,
      "BU_CT_high": 0.0312336376652607,
      "High": 0.0348297538898218,
      "High_CT_high": 0.0295682390012685,
      "Low": 0.0276340443190388,
      "Low_CT_high": 0.030814758489651
    },
    "BJ": {
      "BU": 0.0602649905906859,
      "BU_CT_high": 0.0618214393206394,
      "High": 0.0539115454210985,
      "High_CT_high": 0.0555956428092813,
      "Low": 0.0505825749649055,
      "Low_CT_high": 0.054967873163635
    },
    "BF": {
      "BU": 0.0742784360619598,
      "BU_CT_high": 0.1131381259156605,
      "High": 0.0796665591524676,
      "High_CT_high": 0.1168612550317398,
      "Low": 0.077262952892537,
      "Low_CT_high": 0.1189369043913606
    },
    "BW": {
      "BU": 0.0502939228339384,
      "BU_CT_high": 0.1494071888696171,
      "High": 0.0496812257545149,
      "High_CT_high": 0.1348574301904731,
      "Low": 0.0511609245925359,
      "Low_CT_high": 0.1282803520677698
    },
    "CF": {
      "BU": 0.0553381652693738,
      "BU_CT_high": 0.0533046974943874,
      "High": 0.0522638042495222,
      "High_CT_high": 0.0504073848641631,
      "Low": 0.0557764219062569,
      "Low_CT_high": 0.0564333927860079
    },
    "CI": {
      "BU": 0.0518676910703729,
      "BU_CT_high": 0.0653457824611541,
      "High": 0.0526700803264132,
      "High_CT_high": 0.0657954454682139,
      "Low": 0.0509614525853406,
      "Low_CT_high": 0.0679588065095142
    },
    "CM": {
      "BU": 0.0452574405858191,
      "BU_CT_high": 0.0469473072801859,
      "High": 0.0452827229201861,
      "High_CT_high": 0.0467972569088364,
      "Low": 0.043641373019162,
      "Low_CT_high": 0.051133919398806
    },
    "CD": {
      "BU": 0.0211786601569839,
      "BU_CT_high": 0.0229786216020786,
      "High": 0.0287470788889962,
      "High_CT_high": 0.0252743857001167,
      "Low": 0.0225835710567564,
      "Low_CT_high": 0.0232385630498525
    },
    "CG": {
      "BU": 0.050565874845882,
      "BU_CT_high": 0.0574987611498669,
      "High": 0.0526046847077769,
      "High_CT_high": 0.0546644956690478,
      "Low": 0.0485087510670809,
      "Low_CT_high": 0.0640022530755108
    },
    "DJ": {
      "BU": 0.0560034298016321,
      "BU_CT_high": 0.0971871016216018,
      "High": 0.0485505239881122,
      "High_CT_high": 0.0832864543967415,
      "Low": 0.0503395191566115,
      "Low_CT_high": 0.0937040343018316
    },
    "DZ": {
      "BU": 0.059105802452248,
      "BU_CT_high": 0.1300514051350388,
      "High": 0.059105802452248,
      "High_CT_high": 0.1300514051350388,
      "Low": 0.060097509537782,
      "Low_CT_high": 0.1300651044092168
    },
    "EG": {
      "BU": 0.0588966779653653,
      "BU_CT_high": 0.1150897304925859,
      "High": 0.0588904281415644,
      "High_CT_high": 0.1146670167903871,
      "Low": 0.0589017785586963,
      "Low_CT_high": 0.1151745539965885
    },
    "ER": {
      "BU": 0.0515890183260779,
      "BU_CT_high": 0.1193850227820951,
      "High": 0.0533362747816714,
      "High_CT_high": 0.126861311930701,
      "Low": 0.0609887327708497,
      "Low_CT_high": 0.1529771145074988
    },
    "ET": {
      "BU": 0.0228639304755475,
      "BU_CT_high": 0.0236320569839189,
      "High": 0.022007616405776,
      "High_CT_high": 0.0214817580474014,
      "Low": 0.0225432848482476,
      "Low_CT_high": 0.0232133728962267
    },
    "GA": {
      "BU": 0.0411748013376262,
      "BU_CT_high": 0.0609859832103684,
      "High": 0.0408963079670075,
      "High_CT_high": 0.057312673078256,
      "Low": 0.0410828525584923,
      "Low_CT_high": 0.0595321664394804
    },
    "GH": {
      "BU": 0.049408784666634,
      "BU_CT_high": 0.0715467008812569,
      "High": 0.0498386105016148,
      "High_CT_high": 0.072503579030319,
      "Low": 0.0474953942890303,
      "Low_CT_high": 0.0752056614201883
    },
    "GN": {
      "BU": 0.0314923780429742,
      "BU_CT_high": 0.0361130013187559,
      "High": 0.0313503399441754,
      "High_CT_high": 0.0367973891385083,
      "Low": 0.0315296302772912,
      "Low_CT_high": 0.0406494580042244
    },
    "GM": {
      "BU": 0.051013039820866,
      "BU_CT_high": 0.0463592430182167,
      "High": 0.0563437048349753,
      "High_CT_high": 0.0455381870719342,
      "Low": 0.0629617075541881,
      "Low_CT_high": 0.0680301154821139
    },
    "GW": {
      "BU": 0.0393413956741802,
      "BU_CT_high": 0.0576963738600033,
      "High": 0.0468846217943525,
      "High_CT_high": 0.0582186021154598,
      "Low": 0.0580210074822088,
      "Low_CT_high": 0.1003738126383638
    },
    "GQ": {
      "BU": 0.0380405881618019,
      "BU_CT_high": 0.0499542796384595,
      "High": 0.0380838459785734,
      "High_CT_high": 0.0498842457422415,
      "Low": 0.0402338066643055,
      "Low_CT_high": 0.0647500372387335
    },
    "KE": {
      "BU": 0.039012283933732,
      "BU_CT_high": 0.0441475067439644,
      "High": 0.0382055759290741,
      "High_CT_high": 0.0468397146860116,
      "Low": 0.0384263349044063,
      "Low_CT_high": 0.0412324864574132
    },
    "LR": {
      "BU": 0.0489351532679832,
      "BU_CT_high": 0.053504086626523,
      "High": 0.0469983344213212,
      "High_CT_high": 0.0512959922703644,
      "Low": 0.0524925008369594,
      "Low_CT_high": 0.0478272324066654
    },
    "LY": {
      "BU": 0.070475277713628,
      "BU_CT_high": 0.1653004635117203,
      "High": 0.0703899168369448,
      "High_CT_high": 0.1650994729151366,
      "Low": 0.0704822903228548,
      "Low_CT_high": 0.1584670059490928
    },
    "LS": {
      "BU": 0.0472177228693425,
      "BU_CT_high": 0.0675713136998012,
      "High": 0.044994289010187,
      "High_CT_high": 0.0743071516138944,
      "Low": 0.0485021221349086,
      "Low_CT_high": 0.0612754658631179
    },
    "MA": {
      "BU": 0.0601793255059499,
      "BU_CT_high": 0.1295638805222556,
      "High": 0.0601874795921401,
      "High_CT_high": 0.128438830937042,
      "Low": 0.0644580825413336,
      "Low_CT_high": 0.1318028448526156
    },
    "ML": {
      "BU": 0.0593906481582912,
      "BU_CT_high": 0.0894676991143624,
      "High": 0.0592154973075958,
      "High_CT_high": 0.0888128475269769,
      "Low": 0.0616572805468422,
      "Low_CT_high": 0.090899611307477
    },
    "MZ": {
      "BU": 0.0421232210420938,
      "BU_CT_high": 0.046044946776975,
      "High": 0.0439966427869883,
      "High_CT_high": 0.044774551715972,
      "Low": 0.0407097837826692,
      "Low_CT_high": 0.0463699347138577
    },
    "MR": {
      "BU": 0.0577404376298784,
      "BU_CT_high": 0.1113891524067469,
      "High": 0.0577362490201373,
      "High_CT_high": 0.1080906625636628,
      "Low": 0.0578698808622974,
      "Low_CT_high": 0.1154783992857373
    },
    "MW": {
      "BU": 0.0500364184998053,
      "BU_CT_high": 0.033581552596896,
      "High": 0.0534106371437526,
      "High_CT_high": 0.0371231820823275,
      "Low": 0.0489169510643996,
      "Low_CT_high": 0.0330986482576413
    },
    "NA": {
      "BU": 0.0495630224110969,
      "BU_CT_high": 0.0623002860033473,
      "High": 0.0491400667124464,
      "High_CT_high": 0.0631216295646087,
      "Low": 0.0486282221914061,
      "Low_CT_high": 0.0636830105133262
    },
    "NE": {
      "BU": 0.0584833081972217,
      "BU_CT_high": 0.0651298573659747,
      "High": 0.047523882523523,
      "High_CT_high": 0.0718619437076516,
      "Low": 0.0489221813937697,
      "Low_CT_high": 0.0718456724435365
    },
    "NG": {
      "BU": 0.0488654226945837,
      "BU_CT_high": 0.0798952681037475,
      "High": 0.0503713193347339,
      "High_CT_high": 0.0703846823661,
      "Low": 0.0490155525389442,
      "Low_CT_high": 0.073175108179498
    },
    "RW": {
      "BU": 0.035885808102042,
      "BU_CT_high": 0.0389461870494059,
      "High": 0.0433465465872295,
      "High_CT_high": 0.0482035735990408,
      "Low": 0.0318274014995385,
      "Low_CT_high": 0.0353417071515987
    },
    "SD": {
      "BU": 0.0491207748577753,
      "BU_CT_high": 0.058297659069617,
      "High": 0.0496536756606145,
      "High_CT_high": 0.0583247320660544,
      "Low": 0.0489865081724811,
      "Low_CT_high": 0.0582349907094701
    },
    "SN": {
      "BU": 0.0557689199254137,
      "BU_CT_high": 0.087897816008406,
      "High": 0.0557400340682886,
      "High_CT_high": 0.086080502639921,
      "Low": 0.0567358924666953,
      "Low_CT_high": 0.0953591052377634
    },
    "SL": {
      "BU": 0.0380324955542126,
      "BU_CT_high": 0.0505717262051366,
      "High": 0.0388478393608852,
      "High_CT_high": 0.0465111505051996,
      "Low": 0.0495188093713665,
      "Low_CT_high": 0.0533507589856914
    },
    "SO": {
      "BU": 0.0537709645711021,
      "BU_CT_high": 0.0455344614638172,
      "High": 0.0537709645711021,
      "High_CT_high": 0.0455344871175394,
      "Low": 0.0491643653819073,
      "Low_CT_high": 0.0436026542951155
    },
    "SS": {
      "BU": 0.0556963990922278,
      "BU_CT_high": 0.0833152879864034,
      "High": 0.0546746246386313,
      "High_CT_high": 0.0819128424227,
      "Low": 0.0561413364256861,
      "Low_CT_high": 0.0839607418989253
    },
    "SZ": {
      "BU": 0.048427811361205,
      "BU_CT_high": 0.0493087591348718,
      "High": 0.047805267384607,
      "High_CT_high": 0.049348802782181,
      "Low": 0.0489675771873777,
      "Low_CT_high": 0.0492980112219616
    },
    "TD": {
      "BU": 0.0248016366892799,
      "BU_CT_high": 0.0343810473160537,
      "High": 0.0247857800512733,
      "High_CT_high": 0.0341398440384829,
      "Low": 0.0301870629502847,
      "Low_CT_high": 0.0529487509235267
    },
    "TG": {
      "BU": 0.051398239469,
      "BU_CT_high": 0.0628570699798564,
      "High": 0.0532954187045349,
      "High_CT_high": 0.0603678648359031,
      "Low": 0.0483661716355527,
      "Low_CT_high": 0.0553524397360837
    },
    "TN": {
      "BU": 0.0636120882997661,
      "BU_CT_high": 0.1258043656166244,
      "High": 0.0636120882997661,
      "High_CT_high": 0.1258043656166244,
      "Low": 0.0636472322040711,
      "Low_CT_high": 0.1275046153750503
    },
    "TZ": {
      "BU": 0.0451224239052161,
      "BU_CT_high": 0.0575161082810915,
      "High": 0.0441906531260962,
      "High_CT_high": 0.0555362274676724,
      "Low": 0.0460158287323237,
      "Low_CT_high": 0.0633567213726823
    },
    "UG": {
      "BU": 0.0279989868090967,
      "BU_CT_high": 0.0261746827178397,
      "High": 0.0389763530195378,
      "High_CT_high": 0.0419787191494069,
      "Low": 0.0216396766642033,
      "Low_CT_high": 0.021272667868059
    },
    "ZA": {
      "BU": 0.0390521862461975,
      "BU_CT_high": 0.1210834425262541,
      "High": 0.0391752393529067,
      "High_CT_high": 0.1189524672936869,
      "Low": 0.0390030179095177,
      "Low_CT_high": 0.1237919596474432
    },
    "ZM": {
      "BU": 0.0295040851373095,
      "BU_CT_high": 0.0343809100889335,
      "High": 0.0322712342721151,
      "High_CT_high": 0.0359052542754555,
      "Low": 0.0296239948556297,
      "Low_CT_high": 0.036617530809335
    },
    "ZW": {
      "BU": 0.0347681711999881,
      "BU_CT_high": 0.0666294940591833,
      "High": 0.0361155006023312,
      "High_CT_high": 0.0693648323574654,
      "Low": 0.0337430026031379,
      "Low_CT_high": 0.0683187147690287
    }
  }
}
