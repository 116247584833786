import bbox from '@turf/bbox';

export const worldGeoJSON = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: { ISO_A2: 'SG' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [103.640391472000033, 1.26430898600006],
            [104.003428582000026, 1.26430898600006],
            [104.003428582000026, 1.448635158000059],
            [103.640391472000033, 1.448635158000059],
            [103.640391472000033, 1.26430898600006]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'FJ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [177.0, -18.28799],
            [182.0, -18.28799],
            [182.0, -16.020882256741224],
            [177.0, -16.020882256741224],
            [177.0, -18.28799]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TZ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [29.339997592900346, -11.720938002166735],
            [40.31659, -11.720938002166735],
            [40.31659, -0.95],
            [29.339997592900346, -0.95],
            [29.339997592900346, -11.720938002166735]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'EH' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-17.063423224342571, 20.999752102130827],
            [-8.665124477564191, 20.999752102130827],
            [-8.665124477564191, 27.656425889592356],
            [-17.063423224342571, 27.656425889592356],
            [-17.063423224342571, 20.999752102130827]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CA' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-140.99778, 41.675105088867326],
            [-52.648098720904208, 41.675105088867326],
            [-52.648098720904208, 83.23324],
            [-140.99778, 83.23324],
            [-140.99778, 41.675105088867326]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'US' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-171.791110602891223, 18.91619],
            [-66.96466, 18.91619],
            [-66.96466, 71.35776357694175],
            [-171.791110602891223, 71.35776357694175],
            [-171.791110602891223, 18.91619]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'KZ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [46.46644575377627, 40.662324530594901],
            [87.35997033076265, 40.662324530594901],
            [87.35997033076265, 55.385250149143531],
            [46.46644575377627, 55.385250149143531],
            [46.46644575377627, 40.662324530594901]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'UZ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [55.928917270741096, 37.144994004864685],
            [73.05541710804917, 37.144994004864685],
            [73.05541710804917, 45.586804307632974],
            [55.928917270741096, 45.586804307632974],
            [55.928917270741096, 37.144994004864685]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'PG' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [141.000210402591847, -10.652476088099952],
            [156.019965448224809, -10.652476088099952],
            [156.019965448224809, -2.500002129734007],
            [141.000210402591847, -2.500002129734007],
            [141.000210402591847, -10.652476088099952]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'ID' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [95.293026157617291, -10.359987481327956],
            [141.033851760013818, -10.359987481327956],
            [141.033851760013818, 5.479820868344788],
            [95.293026157617291, 5.479820868344788],
            [95.293026157617291, -10.359987481327956]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'AR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.415435757120093, -55.25],
            [-53.628348965048744, -55.25],
            [-53.628348965048744, -21.83231047942072],
            [-73.415435757120093, -21.83231047942072],
            [-73.415435757120093, -55.25]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CL' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.644395311165454, -55.61183],
            [-66.95992, -55.61183],
            [-66.95992, -17.580011895419332],
            [-75.644395311165454, -17.580011895419332],
            [-75.644395311165454, -55.61183]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CD' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.182336866920252, -13.257226657771831],
            [31.174149204235817, -13.257226657771831],
            [31.174149204235817, 5.256087754737123],
            [12.182336866920252, 5.256087754737123],
            [12.182336866920252, -13.257226657771831]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SO' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [40.98105, -1.68325],
            [51.13387, -1.68325],
            [51.13387, 12.02464],
            [40.98105, 12.02464],
            [40.98105, -1.68325]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'KE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [33.893568969666944, -4.67677],
            [41.855083092643973, -4.67677],
            [41.855083092643973, 5.506],
            [33.893568969666944, 5.506],
            [33.893568969666944, -4.67677]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'KM' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [42.9290771484375, -12.801088279674323],
            [44.879150390625, -12.801088279674323],
            [44.879150390625, -11.054429637103784],
            [42.9290771484375, -11.054429637103784],
            [42.9290771484375, -12.801088279674323]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SD' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.93681, 8.229187933785468],
            [38.410089959473225, 8.229187933785468],
            [38.410089959473225, 22.0],
            [21.93681, 22.0],
            [21.93681, 8.229187933785468]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TD' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.540393507550789, 7.421924546737969],
            [23.886890000000108, 7.421924546737969],
            [23.886890000000108, 23.40972],
            [13.540393507550789, 23.40972],
            [13.540393507550789, 7.421924546737969]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'HT' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.458033616824778, 18.030992743395004],
            [-71.624873216422827, 18.030992743395004],
            [-71.624873216422827, 19.915683905511912],
            [-74.458033616824778, 19.915683905511912],
            [-74.458033616824778, 18.030992743395004]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'DO' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.945112067335558, 17.598564357976599],
            [-68.317943284768972, 17.598564357976599],
            [-68.317943284768972, 19.8849105900821],
            [-71.945112067335558, 19.8849105900821],
            [-71.945112067335558, 17.598564357976599]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'RU' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.982421875, 41.151416124021353],
            [190.0, 41.151416124021353],
            [190.0, 81.2504],
            [26.982421875, 81.2504],
            [-180.0, 41.151416124021353]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BS' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.98, 23.71],
            [-77.0, 23.71],
            [-77.0, 27.04],
            [-78.98, 27.04],
            [-78.98, 23.71]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'FK' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-61.2, -52.3],
            [-57.75, -52.3],
            [-57.75, -51.1],
            [-61.2, -51.1],
            [-61.2, -52.3]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: '-99' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.992078077828978, 58.078884182357285],
            [31.293418409965454, 58.078884182357285],
            [31.293418409965454, 80.657144273593431],
            [4.992078077828978, 80.657144273593431],
            [4.992078077828978, 58.078884182357285]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GL' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.297, 60.03676],
            [-12.20855, 60.03676],
            [-12.20855, 83.64513],
            [-73.297, 83.64513],
            [-73.297, 60.03676]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TF' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [68.72, -49.775],
            [70.56, -49.775],
            [70.56, -48.625],
            [68.72, -48.625],
            [68.72, -49.775]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TL' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [124.968682489116233, -9.393173109579294],
            [127.33592817597463, -9.393173109579294],
            [127.33592817597463, -8.273344821814398],
            [124.968682489116233, -8.273344821814398],
            [124.968682489116233, -9.393173109579294]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'ZA' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.344976840895242, -34.819166355123713],
            [32.830120477028885, -34.819166355123713],
            [32.830120477028885, -22.091312758067588],
            [16.344976840895242, -22.091312758067588],
            [16.344976840895242, -34.819166355123713]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'LS' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.999261915807637, -30.645105889612225],
            [29.325166456832591, -30.645105889612225],
            [29.325166456832591, -28.647501722937569],
            [26.999261915807637, -28.647501722937569],
            [26.999261915807637, -30.645105889612225]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'MX' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.127759999999853, 14.538828640190928],
            [-86.811982388032959, 14.538828640190928],
            [-86.811982388032959, 32.72083],
            [-117.127759999999853, 32.72083],
            [-117.127759999999853, 14.538828640190928]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'UY' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-58.427074144104388, -34.952646579733624],
            [-53.209588995971544, -34.952646579733624],
            [-53.209588995971544, -30.109686374636127],
            [-58.427074144104388, -30.109686374636127],
            [-58.427074144104388, -34.952646579733624]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.987235480429661, -33.768377780900764],
            [-34.729993455533034, -33.768377780900764],
            [-34.729993455533034, 5.244486395687602],
            [-73.987235480429661, 5.244486395687602],
            [-73.987235480429661, -33.768377780900764]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BO' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.59042375352405, -22.872918796482175],
            [-57.498371141170992, -22.872918796482175],
            [-57.498371141170992, -9.761987806846392],
            [-69.59042375352405, -9.761987806846392],
            [-69.59042375352405, -22.872918796482175]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'PE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.410942552399462, -18.347975355708869],
            [-68.665079718689626, -18.347975355708869],
            [-68.665079718689626, -0.05720549886486],
            [-81.410942552399462, -0.05720549886486],
            [-81.410942552399462, -18.347975355708869]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CO' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.99093522817104, -4.298186944194327],
            [-66.87632585312258, -4.298186944194327],
            [-66.87632585312258, 12.437303168177309],
            [-78.99093522817104, 12.437303168177309],
            [-78.99093522817104, -4.298186944194327]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'PA' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.965783047197363, 7.220541490096537],
            [-77.242566494440084, 7.220541490096537],
            [-77.242566494440084, 9.611610012241528],
            [-82.965783047197363, 9.611610012241528],
            [-82.965783047197363, 7.220541490096537]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.941725430021762, 8.225027980985985],
            [-82.546196255203483, 8.225027980985985],
            [-82.546196255203483, 11.217119248901597],
            [-85.941725430021762, 11.217119248901597],
            [-85.941725430021762, 8.225027980985985]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'NI' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.668493415054712, 10.726839097532446],
            [-83.147219000974133, 10.726839097532446],
            [-83.147219000974133, 15.016267198135537],
            [-87.668493415054712, 15.016267198135537],
            [-87.668493415054712, 10.726839097532446]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'HN' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.353325975282814, 12.984685777228975],
            [-83.147219000974133, 12.984685777228975],
            [-83.147219000974133, 16.005405788634391],
            [-89.353325975282814, 16.005405788634391],
            [-89.353325975282814, 12.984685777228975]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SV' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.095554572290979, 13.149016831917137],
            [-87.723502977229401, 13.149016831917137],
            [-87.723502977229401, 14.424132798719086],
            [-90.095554572290979, 14.424132798719086],
            [-90.095554572290979, 13.149016831917137]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GT' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.229248623406306, 13.735337632700734],
            [-88.225022752622024, 13.735337632700734],
            [-88.225022752622024, 17.819326076727478],
            [-92.229248623406306, 17.819326076727478],
            [-92.229248623406306, 13.735337632700734]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BZ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.229121670269279, 15.886937567605171],
            [-88.106812913754382, 15.886937567605171],
            [-88.106812913754382, 18.499982204659901],
            [-89.229121670269279, 18.499982204659901],
            [-89.229121670269279, 15.886937567605171]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'VE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.304951544880055, 0.724452215982012],
            [-59.758284878159195, 0.724452215982012],
            [-59.758284878159195, 12.162307033736099],
            [-73.304951544880055, 12.162307033736099],
            [-73.304951544880055, 0.724452215982012]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GY' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-61.410302903881956, 1.268088283692521],
            [-56.539385748914555, 1.268088283692521],
            [-56.539385748914555, 8.367034816924047],
            [-61.410302903881956, 8.367034816924047],
            [-61.410302903881956, 1.268088283692521]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-58.044694383360678, 1.817667141116601],
            [-53.958044603070903, 1.817667141116601],
            [-53.958044603070903, 6.025291449401664],
            [-58.044694383360678, 6.025291449401664],
            [-58.044694383360678, 1.817667141116601]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: '-99' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-54.524754197799716, 2.053389187015981],
            [9.560016310269134, 2.053389187015981],
            [9.560016310269134, 51.148506171261829],
            [-54.524754197799716, 51.148506171261829],
            [-54.524754197799716, 2.053389187015981]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'EC' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.967765469064361, -4.959128513207389],
            [-75.233722703741947, -4.959128513207389],
            [-75.233722703741947, 1.380923773601822],
            [-80.967765469064361, 1.380923773601822],
            [-80.967765469064361, -4.959128513207389]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'PR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.242427537694354, 17.946553453030077],
            [-65.591003790942949, 17.946553453030077],
            [-65.591003790942949, 18.520601101144351],
            [-67.242427537694354, 18.520601101144351],
            [-67.242427537694354, 17.946553453030077]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'JM' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.33771928578561, 17.701116237859821],
            [-76.199658576141644, 17.701116237859821],
            [-76.199658576141644, 18.524218451404778],
            [-78.33771928578561, 18.524218451404778],
            [-78.33771928578561, 17.701116237859821]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CU' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.974911058273108, 19.855480861891877],
            [-74.17802486845126, 19.855480861891877],
            [-74.17802486845126, 23.188610744717707],
            [-84.974911058273108, 23.188610744717707],
            [-84.974911058273108, 19.855480861891877]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'ZW' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.264225701608012, -22.271611830333935],
            [32.849860874164392, -22.271611830333935],
            [32.849860874164392, -15.507786960515213],
            [25.264225701608012, -15.507786960515213],
            [25.264225701608012, -22.271611830333935]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BW' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.895457797940679, -26.828542982695915],
            [29.43218834810904, -26.828542982695915],
            [29.43218834810904, -17.661815687737374],
            [19.895457797940679, -17.661815687737374],
            [19.895457797940679, -26.828542982695915]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'NA' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [11.734198846085121, -29.045461928017279],
            [25.084443393664571, -29.045461928017279],
            [25.084443393664571, -16.941342868724071],
            [11.734198846085121, -16.941342868724071],
            [11.734198846085121, -29.045461928017279]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SN' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-17.625042690490659, 12.332089952031057],
            [-11.467899135778524, 12.332089952031057],
            [-11.467899135778524, 16.598263658102809],
            [-17.625042690490659, 16.598263658102809],
            [-17.625042690490659, 12.332089952031057]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'ML' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-12.170750291380301, 10.096360785355444],
            [4.270209995143802, 10.096360785355444],
            [4.270209995143802, 24.974574082941],
            [-12.170750291380301, 24.974574082941],
            [-12.170750291380301, 10.096360785355444]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'MR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-17.063423224342571, 14.616834214735505],
            [-4.923337368174231, 14.616834214735505],
            [-4.923337368174231, 27.395744126896005],
            [-17.063423224342571, 27.395744126896005],
            [-17.063423224342571, 14.616834214735505]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BJ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [0.772335646171484, 6.142157701029731],
            [3.797112257511714, 6.142157701029731],
            [3.797112257511714, 12.235635891158211],
            [0.772335646171484, 12.235635891158211],
            [0.772335646171484, 6.142157701029731]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'NE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [0.295646396495101, 11.660167141155968],
            [15.903246697664315, 11.660167141155968],
            [15.903246697664315, 23.47166840259645],
            [0.295646396495101, 23.47166840259645],
            [0.295646396495101, 11.660167141155968]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'NG' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [2.691701694356254, 4.240594183769517],
            [14.577177768622533, 4.240594183769517],
            [14.577177768622533, 13.865923977102227],
            [2.691701694356254, 13.865923977102227],
            [2.691701694356254, 4.240594183769517]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CM' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [8.48881554529089, 1.727672634280296],
            [16.012852410555354, 1.727672634280296],
            [16.012852410555354, 12.859396267137329],
            [8.48881554529089, 12.859396267137329],
            [8.48881554529089, 1.727672634280296]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TG' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-0.049784715159944, 5.928837388528876],
            [1.865240512712319, 5.928837388528876],
            [1.865240512712319, 11.018681748900804],
            [-0.049784715159944, 11.018681748900804],
            [-0.049784715159944, 5.928837388528876]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GH' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-3.244370083011262, 4.710462144383371],
            [1.060121697604927, 4.710462144383371],
            [1.060121697604927, 11.098340969278722],
            [-3.244370083011262, 11.098340969278722],
            [-3.244370083011262, 4.710462144383371]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CI' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.60288021486862, 4.338288479017308],
            [-2.562189500326241, 4.338288479017308],
            [-2.562189500326241, 10.524060777219134],
            [-8.60288021486862, 10.524060777219134],
            [-8.60288021486862, 4.338288479017308]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GN' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-15.130311245168173, 7.309037380396376],
            [-7.832100389019188, 7.309037380396376],
            [-7.832100389019188, 12.586182969610194],
            [-15.130311245168173, 12.586182969610194],
            [-15.130311245168173, 7.309037380396376]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GW' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-16.677451951554573, 11.040411688679526],
            [-13.700476040084325, 11.040411688679526],
            [-13.700476040084325, 12.628170070847347],
            [-16.677451951554573, 12.628170070847347],
            [-16.677451951554573, 11.040411688679526]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'LR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-11.438779466182055, 4.355755113131963],
            [-7.539715135111763, 4.355755113131963],
            [-7.539715135111763, 8.541055202666925],
            [-11.438779466182055, 8.541055202666925],
            [-11.438779466182055, 4.355755113131963]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SL' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-13.246550258832515, 6.785916856305747],
            [-10.23009355309128, 6.785916856305747],
            [-10.23009355309128, 10.046983954300558],
            [-13.246550258832515, 10.046983954300558],
            [-13.246550258832515, 6.785916856305747]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BF' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-5.470564947929006, 9.610834865757141],
            [2.177107781593776, 9.610834865757141],
            [2.177107781593776, 15.116157741755728],
            [-5.470564947929006, 15.116157741755728],
            [-5.470564947929006, 9.610834865757141]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CF' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.459407179429348, 2.267639675298085],
            [27.37422610851749, 2.267639675298085],
            [27.37422610851749, 11.142395127807546],
            [14.459407179429348, 11.142395127807546],
            [14.459407179429348, 2.267639675298085]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CG' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [11.093772820691925, -5.037986748884791],
            [18.453065219809929, -5.037986748884791],
            [18.453065219809929, 3.728196519379452],
            [11.093772820691925, 3.728196519379452],
            [11.093772820691925, -5.037986748884791]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GA' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [8.79799563969317, -3.978826592630547],
            [14.425455763413595, -3.978826592630547],
            [14.425455763413595, 2.326757513839993],
            [8.79799563969317, 2.326757513839993],
            [8.79799563969317, -3.978826592630547]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GQ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.305613234096256, 1.010119533691494],
            [11.285078973036462, 1.010119533691494],
            [11.285078973036462, 2.283866075037736],
            [9.305613234096256, 2.283866075037736],
            [9.305613234096256, 1.010119533691494]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'ZM' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.887842644953874, -17.961228936436484],
            [33.485687697083591, -17.961228936436484],
            [33.485687697083591, -8.238256524288218],
            [21.887842644953874, -8.238256524288218],
            [21.887842644953874, -17.961228936436484]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'MW' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [32.688165317523129, -16.801299737213093],
            [35.771904738108361, -16.801299737213093],
            [35.771904738108361, -9.23059905358906],
            [32.688165317523129, -9.23059905358906],
            [32.688165317523129, -16.801299737213093]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'MZ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [30.17948123548183, -26.742191664336197],
            [40.775475294768995, -26.742191664336197],
            [40.775475294768995, -10.317096042525698],
            [30.17948123548183, -10.317096042525698],
            [30.17948123548183, -26.742191664336197]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SZ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [30.67660851412964, -27.285879408478998],
            [32.071665480281069, -27.285879408478998],
            [32.071665480281069, -25.66019052500895],
            [30.67660851412964, -25.66019052500895],
            [30.67660851412964, -27.285879408478998]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'AO' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [11.640096062881611, -17.930636488519696],
            [24.079905226342845, -17.930636488519696],
            [24.079905226342845, -4.438023369976136],
            [11.640096062881611, -4.438023369976136],
            [11.640096062881611, -17.930636488519696]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BI' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [29.024926385216787, -4.499983412294092],
            [30.75224, -4.499983412294092],
            [30.75224, -2.348486830254238],
            [29.024926385216787, -2.348486830254238],
            [29.024926385216787, -4.499983412294092]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'IL' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [34.265433383935687, 29.501326198844524],
            [35.836396925608625, 29.501326198844524],
            [35.836396925608625, 33.277426459276299],
            [34.265433383935687, 33.277426459276299],
            [34.265433383935687, 29.501326198844524]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'LB' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [35.126052687324545, 33.089040025356283],
            [36.611750115715893, 33.089040025356283],
            [36.611750115715893, 34.644914048800004],
            [35.126052687324545, 34.644914048800004],
            [35.126052687324545, 33.089040025356283]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'MG' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [43.254187046081, -25.601434421493089],
            [50.476536899625529, -25.601434421493089],
            [50.476536899625529, -12.04055673589197],
            [43.254187046081, -12.04055673589197],
            [43.254187046081, -25.601434421493089]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'PS' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [34.927408481594568, 31.353435370401414],
            [35.545665317534542, 31.353435370401414],
            [35.545665317534542, 32.532510687788943],
            [34.927408481594568, 32.532510687788943],
            [34.927408481594568, 31.353435370401414]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GM' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-16.841524624081273, 13.130284125211332],
            [-13.844963344772408, 13.130284125211332],
            [-13.844963344772408, 13.876491807505985],
            [-16.841524624081273, 13.876491807505985],
            [-16.841524624081273, 13.130284125211332]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TN' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [7.524481642292244, 30.307556057246188],
            [11.488787469131012, 30.307556057246188],
            [11.488787469131012, 37.349994411766545],
            [7.524481642292244, 37.349994411766545],
            [7.524481642292244, 30.307556057246188]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'DZ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.684399786809053, 19.057364203360038],
            [11.999505649471613, 19.057364203360038],
            [11.999505649471613, 37.118380642234371],
            [-8.684399786809053, 37.118380642234371],
            [-8.684399786809053, 19.057364203360038]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'JO' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [34.92260257339143, 29.197494615184453],
            [39.195468377444968, 29.197494615184453],
            [39.195468377444968, 33.378686428352225],
            [34.92260257339143, 33.378686428352225],
            [34.92260257339143, 29.197494615184453]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'AE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [51.579518670463273, 22.496947536707136],
            [56.396847365144005, 22.496947536707136],
            [56.396847365144005, 26.055464178973981],
            [51.579518670463273, 26.055464178973981],
            [51.579518670463273, 22.496947536707136]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'QA' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [50.743910760303692, 24.556330878186724],
            [51.606700473848811, 24.556330878186724],
            [51.606700473848811, 26.114582017515868],
            [50.743910760303692, 26.114582017515868],
            [50.743910760303692, 24.556330878186724]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'KW' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [46.568713413281756, 28.526062730416143],
            [48.416094191283946, 28.526062730416143],
            [48.416094191283946, 30.059069932570722],
            [46.568713413281756, 30.059069932570722],
            [46.568713413281756, 28.526062730416143]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'IQ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [38.792340529136084, 29.09902517345229],
            [48.567971225789755, 29.09902517345229],
            [48.567971225789755, 37.385263576805755],
            [38.792340529136084, 37.385263576805755],
            [38.792340529136084, 29.09902517345229]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'OM' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [52.000009800022241, 16.651051133688952],
            [59.808060337162857, 16.651051133688952],
            [59.808060337162857, 26.395934353128979],
            [52.000009800022241, 26.395934353128979],
            [52.000009800022241, 16.651051133688952]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'VU' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [166.5, -20.2],
            [170.2, -20.2],
            [170.2, -13.1],
            [166.5, -13.1],
            [166.5, -20.2]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'FM' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [137.5, 1.1],
            [163.0, 1.1],
            [163.0, 10.1],
            [137.5, 10.1],
            [137.5, 1.1]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'KH' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [102.348099399833018, 10.48654368737523],
            [107.61454796756243, 10.48654368737523],
            [107.61454796756243, 14.570583807834282],
            [102.348099399833018, 14.570583807834282],
            [102.348099399833018, 10.48654368737523]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TH' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [97.375896437573545, 5.691384182147715],
            [105.589038527450157, 5.691384182147715],
            [105.589038527450157, 20.417849636308187],
            [97.375896437573545, 20.417849636308187],
            [97.375896437573545, 5.691384182147715]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'LA' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [100.115987583417848, 13.881091009979956],
            [107.564525181103903, 13.881091009979956],
            [107.564525181103903, 22.464753119389304],
            [100.115987583417848, 22.464753119389304],
            [100.115987583417848, 13.881091009979956]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'MM' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [92.303234490938678, 9.932959906448545],
            [101.180005324307544, 9.932959906448545],
            [101.180005324307544, 28.335945136014345],
            [92.303234490938678, 28.335945136014345],
            [92.303234490938678, 9.932959906448545]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'VN' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [102.170435825613581, 8.599759629750494],
            [109.335269810017223, 8.599759629750494],
            [109.335269810017223, 23.352063300056912],
            [102.170435825613581, 23.352063300056912],
            [102.170435825613581, 8.599759629750494]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'KP' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [124.265624627785314, 37.669070542952724],
            [130.78000735893113, 37.669070542952724],
            [130.78000735893113, 42.985386867843786],
            [124.265624627785314, 42.985386867843786],
            [124.265624627785314, 37.669070542952724]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'KR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.117397902532289, 34.39004588473648],
            [129.4683044780665, 34.39004588473648],
            [129.4683044780665, 38.61224294692785],
            [126.117397902532289, 38.61224294692785],
            [126.117397902532289, 34.39004588473648]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'MN' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [87.751264276076711, 41.597409572916348],
            [119.772823927897505, 41.597409572916348],
            [119.772823927897505, 52.047366034546691],
            [87.751264276076711, 52.047366034546691],
            [87.751264276076711, 41.597409572916348]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'IN' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [68.176645135373406, 7.965534776232332],
            [97.402561476636137, 7.965534776232332],
            [97.402561476636137, 35.494009507787766],
            [68.176645135373406, 35.494009507787766],
            [68.176645135373406, 7.965534776232332]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BD' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [88.084422235062419, 20.670883287025347],
            [92.672720981825563, 20.670883287025347],
            [92.672720981825563, 26.446525580342723],
            [88.084422235062419, 26.446525580342723],
            [88.084422235062419, 20.670883287025347]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BT' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [88.814248488320558, 26.719402981059957],
            [92.103711785859744, 26.719402981059957],
            [92.103711785859744, 28.296438503527217],
            [88.814248488320558, 28.296438503527217],
            [88.814248488320558, 26.719402981059957]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'NP' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [80.088424513676273, 26.397898057556077],
            [88.174804315140918, 26.397898057556077],
            [88.174804315140918, 30.422716986608631],
            [80.088424513676273, 30.422716986608631],
            [80.088424513676273, 26.397898057556077]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'PK' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [60.874248488208792, 23.691965033456711],
            [77.837450799474567, 23.691965033456711],
            [77.837450799474567, 37.133030910789117],
            [60.874248488208792, 37.133030910789117],
            [60.874248488208792, 23.691965033456711]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'AF' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [60.528429803311582, 29.318572496044311],
            [75.158027785140916, 29.318572496044311],
            [75.158027785140916, 38.486281643216415],
            [60.528429803311582, 38.486281643216415],
            [60.528429803311582, 29.318572496044311]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TJ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [67.442219679641312, 36.738171291646921],
            [74.980002475895418, 36.738171291646921],
            [74.980002475895418, 40.960213324541414],
            [67.442219679641312, 40.960213324541414],
            [67.442219679641312, 36.738171291646921]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'KG' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [69.46488691597753, 39.27946320246437],
            [80.259990268885304, 39.27946320246437],
            [80.259990268885304, 43.29833934180337],
            [69.46488691597753, 43.29833934180337],
            [69.46488691597753, 39.27946320246437]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TM' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [52.502459751196149, 35.270663967422294],
            [66.546150343700219, 35.270663967422294],
            [66.546150343700219, 42.751551011723052],
            [52.502459751196149, 42.751551011723052],
            [52.502459751196149, 35.270663967422294]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'IR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [44.109225294782341, 25.078237006118499],
            [63.316631707619592, 25.078237006118499],
            [63.316631707619592, 39.713002631177048],
            [44.109225294782341, 39.713002631177048],
            [44.109225294782341, 25.078237006118499]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SY' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [35.700797967274752, 32.312937526980775],
            [42.349591098811771, 32.312937526980775],
            [42.349591098811771, 37.229872544904097],
            [35.700797967274752, 37.229872544904097],
            [35.700797967274752, 32.312937526980775]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'AM' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [43.582745802592733, 38.741201483712217],
            [46.505719842317973, 38.741201483712217],
            [46.505719842317973, 41.248128567055595],
            [43.582745802592733, 41.248128567055595],
            [43.582745802592733, 38.741201483712217]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [11.027368605196868, 55.361737372450577],
            [23.903378533633802, 55.361737372450577],
            [23.903378533633802, 69.106247260200874],
            [11.027368605196868, 69.106247260200874],
            [11.027368605196868, 55.361737372450577]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BY' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.199493849386187, 51.319503485715657],
            [32.693643019346041, 51.319503485715657],
            [32.693643019346041, 56.169129950578792],
            [23.199493849386187, 56.169129950578792],
            [23.199493849386187, 51.319503485715657]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'UA' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.085608351334855, 45.293308010431126],
            [40.080789015469406, 45.293308010431126],
            [40.080789015469406, 52.335074571331802],
            [22.085608351334855, 52.335074571331802],
            [22.085608351334855, 45.293308010431126]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'PL' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.074521111719434, 49.027395331409622],
            [24.029985792748903, 49.027395331409622],
            [24.029985792748903, 54.851535956432912],
            [14.074521111719434, 54.851535956432912],
            [14.074521111719434, 49.027395331409622]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'AT' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.479969516649021, 46.431817328469549],
            [16.979666782304037, 46.431817328469549],
            [16.979666782304037, 49.039074205107582],
            [9.479969516649021, 49.039074205107582],
            [9.479969516649021, 46.431817328469549]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'HU' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.202298211337364, 45.75948110613615],
            [22.710531447040495, 45.75948110613615],
            [22.710531447040495, 48.623854071642384],
            [16.202298211337364, 48.623854071642384],
            [16.202298211337364, 45.75948110613615]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'MD' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.619336785597795, 45.488283189468291],
            [30.024658644335375, 45.488283189468291],
            [30.024658644335375, 48.467119452501116],
            [26.619336785597795, 48.467119452501116],
            [26.619336785597795, 45.488283189468291]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'RO' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.220192498462836, 43.688444729174719],
            [29.626543409958771, 43.688444729174719],
            [29.626543409958771, 48.220881252630349],
            [20.220192498462836, 48.220881252630349],
            [20.220192498462836, 43.688444729174719]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'LT' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.055800408622417, 53.905702216194754],
            [26.588279249790389, 53.905702216194754],
            [26.588279249790389, 56.37252838807963],
            [21.055800408622417, 56.37252838807963],
            [21.055800408622417, 53.905702216194754]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'LV' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.055800408622417, 55.615106919977634],
            [28.17670942557794, 55.615106919977634],
            [28.17670942557794, 57.970156968815189],
            [21.055800408622417, 57.970156968815189],
            [21.055800408622417, 55.615106919977634]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'EE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.339795363058645, 57.474528306703832],
            [28.13169925305175, 57.474528306703832],
            [28.13169925305175, 59.611090399811332],
            [23.339795363058645, 59.611090399811332],
            [23.339795363058645, 57.474528306703832]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'DE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.988658074577813, 47.302487697939164],
            [15.016995883858669, 47.302487697939164],
            [15.016995883858669, 54.983104153048032],
            [5.988658074577813, 54.983104153048032],
            [5.988658074577813, 47.302487697939164]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BG' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.380525750424592, 41.23448598893053],
            [28.558081495891997, 41.23448598893053],
            [28.558081495891997, 44.234923000661283],
            [22.380525750424592, 44.234923000661283],
            [22.380525750424592, 41.23448598893053]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.15001590341052, 34.919987697889638],
            [26.604195590936229, 34.919987697889638],
            [26.604195590936229, 41.826904608724561],
            [20.15001590341052, 41.826904608724561],
            [20.15001590341052, 34.919987697889638]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.04335127127257, 35.821534735653671],
            [44.793989699081948, 35.821534735653671],
            [44.793989699081948, 42.141484890301342],
            [26.04335127127257, 42.141484890301342],
            [26.04335127127257, 35.821534735653671]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'AL' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.304486118250793, 39.624997666983973],
            [21.020040317476401, 39.624997666983973],
            [21.020040317476401, 42.688247382165571],
            [19.304486118250793, 42.688247382165571],
            [19.304486118250793, 39.624997666983973]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'HR' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.656975538801191, 42.479991360029317],
            [19.390475701584592, 42.479991360029317],
            [19.390475701584592, 46.50375092221983],
            [13.656975538801191, 46.50375092221983],
            [13.656975538801191, 42.479991360029317]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CH' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.022609490593538, 45.776947740250776],
            [10.442701450246631, 45.776947740250776],
            [10.442701450246631, 47.830827541691292],
            [6.022609490593538, 47.830827541691292],
            [6.022609490593538, 45.776947740250776]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'LU' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.674051954784829, 49.442667141307112],
            [6.242751092156993, 49.442667141307112],
            [6.242751092156993, 50.128051662794235],
            [5.674051954784829, 50.128051662794235],
            [5.674051954784829, 49.442667141307112]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [2.513573032246143, 49.529483547557504],
            [6.15665815595878, 49.529483547557504],
            [6.15665815595878, 51.475023708698131],
            [2.513573032246143, 51.475023708698131],
            [2.513573032246143, 49.529483547557504]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'NL' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [3.314971144228537, 50.803721015010581],
            [7.092053256873896, 50.803721015010581],
            [7.092053256873896, 53.510403347378144],
            [3.314971144228537, 53.510403347378144],
            [3.314971144228537, 50.803721015010581]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'PT' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-9.526570603869715, 36.838268540996268],
            [-6.389087693700915, 36.838268540996268],
            [-6.389087693700915, 42.28046865495034],
            [-9.526570603869715, 42.28046865495034],
            [-9.526570603869715, 36.838268540996268]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'ES' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-9.392883673530648, 35.946850083961465],
            [3.039484083680549, 35.946850083961465],
            [3.039484083680549, 43.748337714200993],
            [-9.392883673530648, 43.748337714200993],
            [-9.392883673530648, 35.946850083961465]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'IE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-9.977085740590269, 51.669301255899356],
            [-6.032985398777611, 51.669301255899356],
            [-6.032985398777611, 55.131622219454869],
            [-9.977085740590269, 55.131622219454869],
            [-9.977085740590269, 51.669301255899356]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'NC' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [164.029605747736014, -22.39997608814695],
            [167.120011428086912, -22.39997608814695],
            [167.120011428086912, -20.105645847252354],
            [164.029605747736014, -20.105645847252354],
            [164.029605747736014, -22.39997608814695]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SB' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [156.491357863591304, -10.826367282762106],
            [162.398645868172196, -10.826367282762106],
            [162.398645868172196, -6.599338474151452],
            [156.491357863591304, -6.599338474151452],
            [156.491357863591304, -10.826367282762106]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'NZ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [166.509144321964669, -46.641235446967876],
            [178.517093540762744, -46.641235446967876],
            [178.517093540762744, -34.450661716450369],
            [166.509144321964669, -34.450661716450369],
            [166.509144321964669, -46.641235446967876]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'AU' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [113.338953078262421, -43.634597263362103],
            [153.569469028944184, -43.634597263362103],
            [153.569469028944184, -10.668185723516686],
            [113.338953078262421, -10.668185723516686],
            [113.338953078262421, -43.634597263362103]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'LK' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [79.695166863935128, 5.968369859232155],
            [81.787959018891399, 5.968369859232155],
            [81.787959018891399, 9.824077663609557],
            [79.695166863935128, 9.824077663609557],
            [79.695166863935128, 5.968369859232155]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CN' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [73.675379266254794, 18.197700913968575],
            [135.026311476786645, 18.197700913968575],
            [135.026311476786645, 53.4588],
            [73.675379266254794, 53.4588],
            [73.675379266254794, 18.197700913968575]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TW' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.106188592612398, 21.970571397382113],
            [121.951243931161457, 21.970571397382113],
            [121.951243931161457, 25.295458889257386],
            [120.106188592612398, 25.295458889257386],
            [120.106188592612398, 21.970571397382113]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'IT' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.749955275101655, 36.619987290995397],
            [18.480247023195432, 36.619987290995397],
            [18.480247023195432, 47.115393174826451],
            [6.749955275101655, 47.115393174826451],
            [6.749955275101655, 36.619987290995397]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'DK' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [8.089976840862221, 54.800014553437919],
            [12.690006137755603, 54.800014553437919],
            [12.690006137755603, 57.73001658795485],
            [8.089976840862221, 57.73001658795485],
            [8.089976840862221, 54.800014553437919]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GB' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.572167934591064, 49.959999904981089],
            [1.681530795914682, 49.959999904981089],
            [1.681530795914682, 58.635000108466329],
            [-7.572167934591064, 58.635000108466329],
            [-7.572167934591064, 49.959999904981089]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'IS' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-24.326184047939336, 63.49638296167582],
            [-13.60973222497981, 63.49638296167582],
            [-13.60973222497981, 66.526792304135867],
            [-24.326184047939336, 66.526792304135867],
            [-24.326184047939336, 63.49638296167582]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'AZ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [44.793989699081948, 38.270377509100967],
            [50.392821079312682, 38.270377509100967],
            [50.392821079312682, 41.860675157227305],
            [44.793989699081948, 41.860675157227305],
            [44.793989699081948, 38.270377509100967]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'GE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.955008579270924, 41.064444688474111],
            [46.637908156120581, 41.064444688474111],
            [46.637908156120581, 43.553104153002316],
            [39.955008579270924, 43.553104153002316],
            [39.955008579270924, 41.064444688474111]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'PH' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [117.174274530100661, 5.581003322772276],
            [126.537423944200611, 5.581003322772276],
            [126.537423944200611, 18.505227362537525],
            [117.174274530100661, 18.505227362537525],
            [117.174274530100661, 5.581003322772276]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'MY' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [100.085756870527106, 0.773131415200965],
            [119.181903924639968, 0.773131415200965],
            [119.181903924639968, 6.92805288332454],
            [100.085756870527106, 6.92805288332454],
            [100.085756870527106, 0.773131415200965]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BN' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [114.204016554828371, 4.007636826997754],
            [115.450710483869813, 4.007636826997754],
            [115.450710483869813, 5.447729803891534],
            [114.204016554828371, 5.447729803891534],
            [114.204016554828371, 4.007636826997754]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SI' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.698109978905478, 45.452316392593332],
            [16.564808383864857, 45.452316392593332],
            [16.564808383864857, 46.852385972676963],
            [13.698109978905478, 46.852385972676963],
            [13.698109978905478, 45.452316392593332]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'FI' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.645592889089528, 59.846373196036225],
            [31.516092156711125, 59.846373196036225],
            [31.516092156711125, 70.164193020296253],
            [20.645592889089528, 70.164193020296253],
            [20.645592889089528, 59.846373196036225]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SK' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.879982944413001, 47.758428860050373],
            [22.558137648211755, 47.758428860050373],
            [22.558137648211755, 49.571574001659194],
            [16.879982944413001, 49.571574001659194],
            [16.879982944413001, 47.758428860050373]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CZ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.240111118222558, 48.555305284207208],
            [18.853144158613617, 48.555305284207208],
            [18.853144158613617, 51.117267767941414],
            [12.240111118222558, 51.117267767941414],
            [12.240111118222558, 48.555305284207208]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'ER' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [36.32322, 12.455415757695675],
            [43.081226027200159, 12.455415757695675],
            [43.081226027200159, 17.998307399970315],
            [36.32322, 17.998307399970315],
            [36.32322, 12.455415757695675]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'JP' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [129.408463169472554, 31.029579169228246],
            [145.543137241802697, 31.029579169228246],
            [145.543137241802697, 45.551483466161343],
            [129.408463169472554, 45.551483466161343],
            [129.408463169472554, 31.029579169228246]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'PY' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-62.685057135657885, -27.548499037386293],
            [-54.292959560754518, -27.548499037386293],
            [-54.292959560754518, -19.342746677327426],
            [-62.685057135657885, -19.342746677327426],
            [-62.685057135657885, -27.548499037386293]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'YE' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [42.60487267433362, 12.585950425664876],
            [53.108572625547509, 12.585950425664876],
            [53.108572625547509, 19.000003363516058],
            [42.60487267433362, 19.000003363516058],
            [42.60487267433362, 12.585950425664876]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SA' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [34.632336053207979, 16.347891343648683],
            [55.666659376859826, 16.347891343648683],
            [55.666659376859826, 32.161008816042667],
            [34.632336053207979, 32.161008816042667],
            [34.632336053207979, 16.347891343648683]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'AQ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-180.0, -90.0],
            [180.0, -90.0],
            [180.0, -63.270660489504621],
            [-180.0, -63.270660489504621],
            [-180.0, -90.0]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: '-99' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [32.731780226377452, 35.000344550103506],
            [34.576473829900465, 35.000344550103506],
            [34.576473829900465, 35.671595567358793],
            [32.731780226377452, 35.671595567358793],
            [32.731780226377452, 35.000344550103506]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'CY' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [32.25666710788596, 34.571869411755443],
            [34.004880812320039, 34.571869411755443],
            [34.004880812320039, 35.17312470147138],
            [32.25666710788596, 35.17312470147138],
            [32.25666710788596, 34.571869411755443]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'MA' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-17.020428432675772, 21.420734157796577],
            [-1.124551153966308, 21.420734157796577],
            [-1.124551153966308, 35.75998810479399],
            [-17.020428432675772, 35.75998810479399],
            [-17.020428432675772, 21.420734157796577]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'EG' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.70007, 22.0],
            [36.86623, 22.0],
            [36.86623, 31.58568],
            [24.70007, 31.58568],
            [24.70007, 22.0]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'LY' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.319410841518163, 19.580470000000105],
            [25.16482, 19.580470000000105],
            [25.16482, 33.136995754523241],
            [9.319410841518163, 33.136995754523241],
            [9.319410841518163, 19.580470000000105]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'ET' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [32.95418, 3.42206],
            [47.78942, 3.42206],
            [47.78942, 14.959430000000168],
            [32.95418, 14.959430000000168],
            [32.95418, 3.42206]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'DJ' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [41.661760000000129, 10.92687856693442],
            [43.317852410664671, 10.92687856693442],
            [43.317852410664671, 12.699638576707116],
            [41.661760000000129, 12.699638576707116],
            [41.661760000000129, 10.92687856693442]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: '-99' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [42.55876, 7.99688],
            [48.948206414593471, 7.99688],
            [48.948206414593471, 11.462039699748857],
            [42.55876, 11.462039699748857],
            [42.55876, 7.99688]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'UG' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [29.579466180140884, -1.443322442229785],
            [35.03599, -1.443322442229785],
            [35.03599, 4.249884947362048],
            [29.579466180140884, 4.249884947362048],
            [29.579466180140884, -1.443322442229785]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'RW' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [29.024926385216787, -2.917857761246097],
            [30.816134881317712, -2.917857761246097],
            [30.816134881317712, -1.134659112150416],
            [29.024926385216787, -1.134659112150416],
            [29.024926385216787, -2.917857761246097]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'BA' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.750026075918981, 42.65],
            [19.59976, 42.65],
            [19.59976, 45.233776760430942],
            [15.750026075918981, 45.233776760430942],
            [15.750026075918981, 42.65]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'MK' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.463175083099202, 40.84272695572588],
            [22.952377150166452, 40.84272695572588],
            [22.952377150166452, 42.320259507815088],
            [20.463175083099202, 42.320259507815088],
            [20.463175083099202, 40.84272695572588]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'RS' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.829824792873946, 42.245224397061861],
            [22.986018507588483, 42.245224397061861],
            [22.986018507588483, 46.17172984474454],
            [18.829824792873946, 46.17172984474454],
            [18.829824792873946, 42.245224397061861]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'ME' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.450016883020862, 41.877550679783496],
            [20.3398, 41.877550679783496],
            [20.3398, 43.52384],
            [18.450016883020862, 43.52384],
            [18.450016883020862, 41.877550679783496]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'XK' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.0707, 41.84711],
            [21.77505, 41.84711],
            [21.77505, 43.27205],
            [20.0707, 43.27205],
            [20.0707, 41.84711]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'TT' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-61.95, 10.0],
            [-60.895, 10.0],
            [-60.895, 10.89],
            [-61.95, 10.89],
            [-61.95, 10.0]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'SS' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.886979580860668, 3.509171604222463],
            [35.298007118232981, 3.509171604222463],
            [35.298007118232981, 12.248007757149992],
            [23.886979580860668, 12.248007757149992],
            [23.886979580860668, 3.509171604222463]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { ISO_A2: 'ST' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.179809570312499, -0.1647946946615406],
            [7.7398681640625, -0.1647946946615406],
            [7.7398681640625, 1.9332268264771233],
            [6.179809570312499, 1.9332268264771233],
            [6.179809570312499, -0.1647946946615406]
          ]
        ]
      }
    }
  ]
};

export function getCountryBoundsNWSE (iso) {
  iso = iso.toUpperCase();
  const feat = worldGeoJSON.features.find(f => f.properties.ISO_A2 === iso);
  const [w, s, e, n] = bbox(feat);
  return [
    [w, n], // NW
    [e, s] // SE
  ];
}
